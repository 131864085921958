import React from "react";
import { useParams } from "react-router-dom";
import UnityWebViewer from "../components/UnityWebViewer";


const EmbedPage = () => {
  const { experienceId } = useParams();

  return (
    <div className="App">
      <UnityWebViewer experienceId={experienceId} embed/>
    </div>
  );
};

export default EmbedPage;
