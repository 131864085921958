// Sidebar.jsx
import React, { useEffect } from "react";
import { Box, Typography, Divider } from "@mui/material";
import logo from "../assets/logo.png"; // adjust the path if needed
import { ReactComponent as NIHLogo } from "../assets/nih.svg";
import { ReactComponent as NSFLogo } from "../assets/NSF_logo.svg";

const Sidebar = ({ focusedElement, currentEnvironment, system }) => {
  useEffect(() => {
    console.log(currentEnvironment);
  }, [currentEnvironment]);

  return (
    <Box
      sx={{
        position: "absolute", // overlay on top of Unity web app
        top: 0,
        left: 0,
        width: "250px",
        // Account for vertical padding (py: 1 = 8px top and bottom by default)
        height: "calc(100% - 32px)",
        backgroundColor: "#212121", // dark background
        color: "#fff",
        px: 2, // horizontal padding remains
        py: 2, // vertical padding remains
        zIndex: 1000, // ensure it appears on top
        overflowY: "auto",
        boxShadow: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Logo at the top */}
      <Box sx={{ pl: 2, pt: 2, mb: 2 }} align="left">
        <img src={logo} alt="Logo" style={{ width: "55%" }} />{" "}
      </Box>
      {system && (
        <Card label="Experience" content={system?.name} color="#7CFDFF" />
      )}
      {currentEnvironment && (
        <Card
          label="Current environment"
          content={currentEnvironment.environmentName}
          description={currentEnvironment.description}
        />
      )}

      {focusedElement && (
        <Card
          label="Selected element"
          variant="roundedColored"
          content={focusedElement.labelData.displayName}
          description={focusedElement.labelData.description}
        />
      )}
      {!focusedElement && (
        <Card
          label="Instructions"
          variant="rounded"
          content="Navigation"
          description="Scroll to zoom in and out. Click on objects to see more about them."
        />
      )}

      {!focusedElement && (
        <Card
          label="Instructions"
          variant="rounded"
          content="AI Guide"
          description="Enable your microphone to use the AI Guide. Answer the questions out loud and get an answer."
        />
      )}

      <Box sx={{ mt: "auto" }} align="left">
        <Divider color="#666" />
        <Typography variant="overline">ABout</Typography>
        <Typography variant="caption" sx={{ p: 1 }}>
          Made in collaboration with the Innovative Genomics Institute and
          Lawrence Hall of Science at UC Berkeley.
        </Typography>
      </Box>
      {/* <Divider color="#666" /> */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="caption" align="left">
          Funding support from
        </Typography>
        <NIHLogo width="50px" />
        <NSFLogo width="35px" />
      </Box>
    </Box>
  );
};

const Card = ({label, content, description, variant, color}) => {
  // Define variant-based styles
  const variantStyles = {
    roundedColored: {
      border: "1px solid rgba(255,255,255,0.12)",
      borderRadius: "10px",
      p: 2,
      pt: 0,
      pb: 2,
      backgroundColor: "#7CFDFF",
      color: "#333",
    },
    rounded: {
      border: "1px solid rgba(255,255,255,0.12)",
      borderRadius: "10px",
      p: 2,
      pt: 0,
      pb: 2,

    },
    default: {},
  };
  return (
    <Box
      sx={{
        color: color ? color : "inherit",
        pl: 2,
    
        mb: 3,
        ...variantStyles[variant],
      }}
      align="left"
    >
      <Typography variant="overline" gutterBottom color="inherit">
        {label}
      </Typography>
      <Typography variant={variant ? "h5" : "h4"} gutterBottom color="inherit">
        {content}
      </Typography>
      <Typography variant="body1" align="left" color="inherit">
        {description}
      </Typography>
      {/* {!variant && <Divider variant="fullWidth" sx={{ borderColor: "rgba(255,255,255,0.12)", margin: 2 }} />} */}
    </Box>
  );
}

export default Sidebar;
