import React from "react";
import "./LoadingAnimation.scss";

const LoadingAnimation = () => {
  return (
    <div className="loading-container">
      Loading
      <span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
    </div>
  );
};

export default LoadingAnimation;
