import { Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./LoadingSequence.scss";

const LoadingSequence = ({ systemData, onComplete, delay = 500 }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [envList, setEnvList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [displayedMessages, setDisplayedMessages] = useState([]);

  // Extract environments from systemData.json
  useEffect(() => {
    if (!systemData || !systemData.json) return;
    try {
      const parsedData = JSON.parse(systemData.json);
      const environments = Object.values(parsedData.environments);
      setEnvList(environments);
    } catch (error) {
      console.error("Failed to parse environment JSON data", error);
    }
  }, [systemData]);

  // Build the messages list from systemData and envList.
  useEffect(() => {
    let tempMessages = [];
    if (systemData) {
      // Message 1: Loading system details.
      tempMessages.push(`Loading ${systemData.name}`);

      // Define an array of possible start words.
      const startWords = ["Creating", "Building", "Downloading", "Setting up", "Unbundling", "Investigating", "Loading"];

      // Message 2: For each environment, add a message with a random start word.
      envList.forEach((env) => {
        const word = startWords[Math.floor(Math.random() * startWords.length)];
        tempMessages.push(`${word} ${env.environmentName}`);
      });

      // Message 3: Final message.
      tempMessages.push("Almost done...");
      setMessages(tempMessages);
    }
  }, [envList, systemData]);

  // Timer: add a new message to the displayed list every [delay] ms.
  useEffect(() => {
    if (currentStep < messages.length) {
      const timer = setTimeout(() => {
        setDisplayedMessages((prev) => {
          // Create a new message object with a unique id.
          const newMsg = { id: currentStep, text: messages[currentStep] };
          const newMessages = [...prev, newMsg];
          // Keep only the last 4 messages.
          if (newMessages.length > 4) {
            newMessages.shift();
          }
          return newMessages;
        });
        setCurrentStep((prev) => prev + 1);
      }, delay);
      return () => clearTimeout(timer);
    } else {
      // When all messages have been processed, call onComplete (if provided) after a delay.
      if (onComplete) {
        const timer = setTimeout(() => {
          onComplete();
        }, delay);
        return () => clearTimeout(timer);
      }
    }
  }, [currentStep, messages, delay, onComplete]);

  return (
    <Box
      sx={{
        mt: "60px",
        width: "250px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <TransitionGroup>
        {displayedMessages.map((msg) => (
          <CSSTransition key={msg.id} timeout={300} classNames="message">
            <Typography
              variant="body1"
              color="white"
              sx={{ mb: 2 }}
              align="left"
            >
              {msg.text}
            </Typography>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Box>
  );
};

export default LoadingSequence;
