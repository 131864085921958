import React, { useCallback, useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import VoiceRecorder from "./VoiceRecorder";
import Sidebar from "./Sidebar";
import { Box, CircularProgress, Typography } from "@mui/material";
import EnvironmentMap from "./EnvironmentMap";
import Label from "./Label";
import DustParticles from "./DustParticles";
import logo from "../assets/logo.png"; // adjust the path if needed
import LoadingAnimation from "./LoadingAnimation";
import LoadingSequence from "./LoadingSequence";

const UnityWebViewer = ({ experienceId, embed }) => {

   const buildPrefix = "/webplayer/10kScienceWeb_1.1.70_Production";
   const voiceRecorderRef = useRef(null);
   const [currentElement, setCurrentElement] = useState(null);
   const [systemData, setSystemData] = useState(null);
   const [focusedElement, setFocusedElement] = useState(null);
   const [labelPosition, setLabelPosition] = useState(null);
   const [currentEnvironment, setCurrentEnvironment] = useState(null);
   const [systemLoaded, setSystemLoaded] = useState(false);



  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
  } = useUnityContext({
    loaderUrl: `${buildPrefix}.loader.js`,
    dataUrl: `${buildPrefix}.data`,
    frameworkUrl: `${buildPrefix}.framework.js`,
    codeUrl: `${buildPrefix}.wasm`,
  });

  const playerOffset = embed ? 0 : 250;
  const dragStart = useRef(null);

   const sendMessageToUnity = (gameObject, methodName, value) => {
     console.log(unityProvider);
     sendMessage(gameObject, methodName, value);
   };

   const handleElementSelected = useCallback((elementData, selectionPoint) => {
     const parsedElement =
       typeof elementData === "string" ? JSON.parse(elementData) : elementData;
     setCurrentElement(parsedElement);
   }, []);

   const handleEnvironmentChanged = useCallback((environmentData) => {
     try {
       const parsedEnvironment =
         typeof environmentData === "string"
           ? JSON.parse(environmentData)
           : environmentData;
       setFocusedElement(null);
       console.log(parsedEnvironment);
       setCurrentEnvironment(parsedEnvironment);
     } catch (error) {
       console.error("Failed to parse environmentData", error);
     }
   }, []);

   const handleRecordingStarted = useCallback((instanceId) => {
     if (voiceRecorderRef.current) {
       voiceRecorderRef.current.startRecording(instanceId);
     }
   }, []);

   const handleUploadAudio = useCallback((presignedUrl) => {
     if (voiceRecorderRef.current) {
       voiceRecorderRef.current.uploadFileToS3(presignedUrl);
     }
   }, []);

   const handleReadyToLoadExperience = useCallback(
     (message) => {
       console.log(experienceId);
       sendMessageToUnity("EventDispatcher", "LoadExperience", experienceId);
     },
     [isLoaded, experienceId]
   );

   const handleSystemLoaded = useCallback((systemData) => {
     const parsedSystem =
       typeof systemData === "string" ? JSON.parse(systemData) : systemData;
     console.log("SYSTEM DATA", parsedSystem);
     setSystemData(parsedSystem);
   }, []);

   const handleSystemFullyLoaded = useCallback(() => {
     console.log("SYSTEM IS NOW LOADED");
     setSystemLoaded(true);
   }, []);

   const handleElementDeselected = useCallback((message) => {
     console.log("Element deselect");
     setCurrentElement(null);
   }, []);

   useEffect(() => {
     addEventListener("elementSelected", handleElementSelected);
     addEventListener("elementDeselected", handleElementDeselected);
     addEventListener("environmentChanged", handleEnvironmentChanged);
     addEventListener("recordingStarted", handleRecordingStarted);
     addEventListener("uploadAudioFile", handleUploadAudio);
     addEventListener("readyToLoadExperience", handleReadyToLoadExperience);
     addEventListener("systemLoaded", handleSystemLoaded);
     addEventListener("systemFullyLoaded", handleSystemFullyLoaded);

     return () => {
       removeEventListener("elementSelected", handleElementSelected);
       removeEventListener("elementDeselected", handleElementDeselected);
       removeEventListener("environmentChanged", handleEnvironmentChanged);
       removeEventListener("recordingStarted", handleRecordingStarted);
       removeEventListener("uploadAudioFile", handleUploadAudio);
       removeEventListener(
         "readyToLoadExperience",
         handleReadyToLoadExperience
       );
       removeEventListener("systemLoaded", handleSystemLoaded);
       removeEventListener("systemFullyLoaded", handleSystemFullyLoaded);
     };
   }, [
     addEventListener,
     removeEventListener,
     handleElementSelected,
     handleElementDeselected,
     handleEnvironmentChanged,
     handleSystemLoaded,
     handleSystemFullyLoaded,
     handleRecordingStarted,
     handleUploadAudio,
     handleReadyToLoadExperience,
     isLoaded,
   ]);

   const handleMouseDown = (event) => {
     dragStart.current = { x: event.clientX, y: event.clientY };
     setFocusedElement(null);
     setLabelPosition(null);
   };

   const handleMouseUp = (event) => {
     const threshold = 5;
     const dx = event.clientX - dragStart.current.x;
     const dy = event.clientY - dragStart.current.y;
     const distance = Math.sqrt(dx * dx + dy * dy);

     if (distance <= threshold) {
       if (currentElement) {
         setFocusedElement(currentElement);
       } else {
         setFocusedElement(null);
       }
       const { offsetX, offsetY } = event.nativeEvent;
       setLabelPosition({ x: offsetX, y: offsetY });
     }
   };

   const doneRecording = () => {
     sendMessageToUnity("EventDispatcher", "RecordingStopped");
   };

   const assessmentComplete = () => {
     sendMessageToUnity("EventDispatcher", "AssessmentComplete");
   };

   const changeEnvironment = (environmentId) => {
     console.log("Changing env to" + environmentId);
     sendMessageToUnity("EventDispatcher", "ChangeEnvironment", environmentId);
   };

   const isChrome =
     typeof window !== "undefined" &&
     /Chrome/.test(navigator.userAgent) &&
     /Google Inc/.test(navigator.vendor);

   const overlayStyles = {
     position: "absolute",
     top: 0,
     left: 0,
     width: "100%",
     height: "100%",
     backgroundColor: "rgba(255, 255, 255, 0.8)",
     zIndex: 1000,
     display: "flex",
     flexDirection: "column",
     justifyContent: "center",
     alignItems: "center",
   };

  // Check if the user is on a mobile device
  const isMobile =
    typeof window !== "undefined" &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  // If mobile, display a message and don't load the experience
  if (isMobile) {
    return (
      <div
        className="fullscreen-loader"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          // padding: "40px",
          

          textAlign: "left",
        }}
      >
        <DustParticles />
        <Box sx={{ mr: "5%" }}>
          <img src={logo} alt="Logo" style={{ width: "80%" }} />
        </Box>
        <Box width="80%" color="white"  sx={{ mr: "80px", mt:"20px" }}>
          <Typography variant="h4" gutterBottom>
            Hi there!
          </Typography>
          <Typography variant="body1">
            Thanks for your interest in 10k Science. Currently,
            it’s optimized for desktop computers. Please switch to a desktop for
            the best experience—we’re working on mobile support for the future!
          </Typography>
        </Box>
      </div>
    );
  }

 


 

  return (
    <div
      className="content"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: `${playerOffset}px`,
          width: `calc(100% - ${playerOffset}px)`,
          height: "100%",
        }}
      >
        <Unity
          unityProvider={unityProvider}
          style={{ width: "100%", height: "100%" }}
          devicePixelRatio={window.devicePixelRatio}
          width="100%"
          height="100%"
        />
      </div>

      {embed ? null : (
        <Sidebar
          focusedElement={focusedElement}
          currentEnvironment={currentEnvironment}
          system={systemData}
        />
      )}

      {labelPosition && focusedElement && (
        <Label
          labelPosition={labelPosition}
          displayName={focusedElement.labelData.displayName}
          alignment="left"
          offsetLeft={playerOffset}
        />
      )}

      {systemData && (
        <EnvironmentMap
          systemData={systemData}
          currentEnvironmentId={currentEnvironment.id}
          changeEnvironment={changeEnvironment}
        />
      )}

      <VoiceRecorder
        doneRecording={doneRecording}
        assessmentComplete={assessmentComplete}
        ref={voiceRecorderRef}
      />

      {!systemLoaded && (
        <div style={overlayStyles}>
          <div>
            <div className="fullscreen-loader">
              <DustParticles />
              <Box sx={{ mr: "5%" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "100%", maxWidth: "400px" }}
                />
              </Box>
              {isChrome ? (
                <>
                  <Box style={{ width: "200px", maxWidth: "400px" }}>
                    {!systemData ? (
                      <LoadingAnimation />
                    ) : (
                      <LoadingSequence systemData={systemData} />
                    )}
                  </Box>
                </>
              ) : (
                <Typography
                  variant="body1"
                  color="white"
                  width="450px"
                  align="left"
                  sx={{ mt: "60px" }}
                >
                  Thank you for visiting. This experience uses advanced web
                  features currently available only in Google Chrome. Please
                  switch to Chrome for the best experience. We appreciate your
                  understanding.
                </Typography>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnityWebViewer;
