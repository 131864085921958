import React from 'react';
import UnityWebViewer from '../components/UnityWebViewer';


const HomePage = () => (
  <div className="App">
    <UnityWebViewer experienceId="9225a575-dd6c-4774-8ce0-e1a9a778ce11" />
  </div>
);

export default HomePage;
