import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";

// import SendMessageButton from "./components/SendMessageButton";
import ExperiencePage from "./pages/ExperiencePage";
import HomePage from "./pages/HomePage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EmbedPage from "./pages/EmbedPage";

const theme = createTheme({
  typography: {
    fontFamily: '"area-normal", sans-serif',
    //     typography: {
    //     h1: {
    //       fontFamily: '"area-extended", sans-serif',
    //       fontSize: '3rem',
    //       fontWeight: 300,
    //       lineHeight: 1.2,
    //     },

    //     font-family: area-extended, sans-serif;
    // font-weight: 900;
    // font-style: normal;
    //     h2: {
    //       fontFamily: '"Your Font", sans-serif',
    //       fontSize: '2.5rem',
    //       fontWeight: 300,
    //       lineHeight: 1.3,
    //     },
    //     h3: {
    //       fontFamily: '"Your Font", sans-serif',
    //       fontSize: '2rem',
    //       fontWeight: 400,
    //       lineHeight: 1.4,
    //     },
    h4: {
      fontFamily: '"area-normal", sans-serif',
      fontSize: "1.55rem",
      fontWeight: 900,
      lineHeight: 1.5,
    },
    h5: {
      fontFamily: '"area-normal", sans-serif',
      fontSize: "1.2rem",
      fontWeight: 900,
      lineHeight: 1.6,
    },
    //     h6: {
    //       fontFamily: '"Your Font", sans-serif',
    //       fontSize: '1.25rem',
    //       fontWeight: 500,
    //       lineHeight: 1.6,
    //     },
    //     subtitle1: {
    //       fontFamily: '"Your Font", sans-serif',
    //       fontSize: '1rem',
    //       fontWeight: 400,
    //       lineHeight: 1.75,
    //     },
    //     subtitle2: {
    //       fontFamily: '"Your Font", sans-serif',
    //       fontSize: '0.875rem',
    //       fontWeight: 500,
    //       lineHeight: 1.57,
    //     },
    body1: {
      // fontFamily: '"Your Font", sans-serif',
      // fontSize: '1rem',
      // fontWeight: 400,
      lineHeight: 1.4,
    },
    //     body2: {
    //       fontFamily: '"Your Font", sans-serif',
    //       fontSize: '0.875rem',
    //       fontWeight: 400,
    //       lineHeight: 1.43,
    //     },
    //     button: {
    //       fontFamily: '"Your Font", sans-serif',
    //       fontSize: '0.875rem',
    //       fontWeight: 500,
    //       textTransform: 'uppercase',
    //     },
    caption: {
      // fontFamily: '"Your Font", sans-serif',
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.4,
      display: "block",
    },
    overline: {
      fontFamily: '"area-normal", sans-serif',
      fontSize: "0.65rem",
      fontWeight: 400,
      textTransform: "uppercase",
      lineHeight: 2.0,
      color: "#999",
    },
  },
});




const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/experiences/:experienceId"
            element={<ExperiencePage />}
          />
          <Route
            path="/experiences/embed/:experienceId"
            element={<EmbedPage />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
