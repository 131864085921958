import React, { useEffect, useState } from "react";
import "./EnvironmentMap.scss";
import { Box, Typography } from "@mui/material";
import Label from "./Label";

const EnvironmentMap = ({ systemData, currentEnvironmentId, changeEnvironment }) => {
  const [envList, setEnvList] = useState([]);
  const [positions, setPositions] = useState({});
  const [layoutDimensions, setLayoutDimensions] = useState({
    width: 400,
    height: 400,
  });

  // Parse systemData.json and collapse the environments object.
  useEffect(() => {
    if (!systemData || !systemData.json) return;
    try {
      const parsedData = JSON.parse(systemData.json);
      console.log("SYSTEM DATA", parsedData)
      const environments = Object.values(parsedData.environments);
      setEnvList(environments);
    } catch (error) {
      console.error("Failed to parse environment JSON data", error);
    }
  }, [systemData]);

  // Compute layout positions and overall container size from envList.
  useEffect(() => {
    if (!envList.length) return;

    // Group environments by magnification (default to 1)
    const groups = {};
    envList.forEach((env) => {
      const mag = env.magnification || 1;
      if (!groups[mag]) groups[mag] = [];
      groups[mag].push(env);
    });
    // Sort groups in ascending order so that lower magnification items are at the bottom.
    const sortedMags = Object.keys(groups)
      .map(Number)
      .sort((a, b) => a - b);

    // Define layout constants.
    const minHorizontalSpacing = 40; // minimum distance between nodes horizontally
    const verticalSpacing = 65; // vertical space between levels
    const margin = 30;
    const numLevels = sortedMags.length;

    // Dynamically compute branchWidth: maximum group width among all levels.
    let branchWidth = 0;
    sortedMags.forEach((mag) => {
      const groupLength = groups[mag].length;
      const groupWidth = (groupLength + 1) * minHorizontalSpacing;
      if (groupWidth > branchWidth) branchWidth = groupWidth;
    });

    // Compute positions for each node.
    // For level 0 (lowest magnification), we place the nodes at the bottom.
    const newPositions = {};
    sortedMags.forEach((mag, levelIndex) => {
      // Calculate y so that level 0 is at the bottom:
      // y = verticalSpacing * (numLevels - 1 - levelIndex) + margin.
      const y = verticalSpacing * (numLevels - 1 - levelIndex) + margin;
      const group = groups[mag];
      // Use the computed branchWidth to evenly distribute nodes in this group.
      const horizontalSpacing = branchWidth / (group.length + 1);
      group.forEach((env, index) => {
        const x = (index + 1) * horizontalSpacing + margin;
        newPositions[env.id] = { x, y };
      });
    });
    setPositions(newPositions);

    // Compute overall container dimensions.
    const newWidth = branchWidth + 0.5 * margin;
    const newHeight = verticalSpacing * (numLevels - 1) + 2 * margin;
    setLayoutDimensions({ width: newWidth, height: newHeight });
  }, [envList]);

  // Format magnification similar to your Unity function.
  const formatMagnification = (mag) => {
    if (!mag) return "0";
    const magnitude = Math.floor(Math.log10(Math.abs(mag)));
    const scale = Math.pow(10, magnitude - 1);
    const sigDigits = Math.round(mag / scale);
    return `${(sigDigits * scale).toLocaleString()}x`;
  };

  const renderConnectors = () => {
    if (!envList.length) return null;
    const connectors = envList.flatMap((env) => {
      if (!env.elements) return [];
      return env.elements.map((element, i) => {
        if (!element.targetEnvironmentId) return null;
        const source = positions[env.id];
        const target = positions[element.targetEnvironmentId];
        if (!source || !target) return null;

        // Compute vertical difference.
        const dy = target.y - source.y;

        // Define key points:
        const S = { ...source }; // source center
        const T = { ...target }; // target center

        // P: 1/3 of the vertical distance from source (keeps source x)
        const P = { x: S.x, y: S.y + dy / 3 };
        // Q: 2/3 of the vertical distance, with x forced to target.x
        const Q = { x: T.x, y: S.y + (2 * dy) / 3 };

        // Choose an offset for the curve
        const offset = (Q.y - P.y) / 1.5;

        // Build the path:
        const pathD = `
          M ${S.x} ${S.y}
          L ${P.x} ${P.y}
          C ${P.x} ${P.y + offset}, ${Q.x} ${Q.y - offset}, ${Q.x} ${Q.y}
          L ${T.x} ${T.y}
        `;

        return (
          <path
            key={`${env.id}-${element.targetEnvironmentId}-${i}`}
            d={pathD}
            fill="none"
            stroke="#ccc"
            strokeWidth="1"
          />
        );
      });
    });
    return connectors.filter((item) => item !== null);
  };

  // Render nodes as spheres with labels underneath.
  const renderNodes = () => {
    return envList.map((env, i) => {
      const pos = positions[env.id] || { x: 0, y: 0 };
      return (
        <div
          key={`${env.id}_${i}`}
          className="environment-node"
          style={{ left: pos.x, top: pos.y }}
        >
          <NodeSphere environment={env} currentEnvironmentId={currentEnvironmentId} changeEnvironment={changeEnvironment} />
          {/* Uncomment and customize the label if desired:
          <div className="node-label">
            {env.environmentName}
            {env.magnification ? ` (${formatMagnification(env.magnification)})` : ""}
          </div>
          */}
        </div>
      );
    });
  };

  return (
    <div
      className="map-container"
      style={{
        width: layoutDimensions.width,
        height: layoutDimensions.height,
      }}
    >
      <svg
        className="connectors"
        width={layoutDimensions.width}
        height={layoutDimensions.height}
        viewBox={`0 0 ${layoutDimensions.width} ${layoutDimensions.height}`}
      >
        {renderConnectors()}
      </svg>
      {renderNodes()}
    </div>
  );
};

const NodeSphere = ({environment, changeEnvironment, currentEnvironmentId}) => {
  const [current, setCurrent] = useState(false);
  const [labelShowing, setLabelShowing] = useState(false);
  const [labelPosition, setLabelPosition] = useState({x:0,y:0})

  useEffect(() => {
    
    environment && console.log("ENV", environment);
  }, [environment])


  useEffect(() => {
   
    if(currentEnvironmentId === environment.id)
    {
      setCurrent(true)
    }
    else{
      setCurrent(false)
    }
  }, [currentEnvironmentId])

  const handleClicked = (event) => {
     console.log(environment);
    event.stopPropagation();
    changeEnvironment && changeEnvironment(environment.id);
  }

  const showLabel = (event) => {
    const sphereRect = event.currentTarget.getBoundingClientRect();
    const parentRect = event.currentTarget.parentNode.getBoundingClientRect();

    // Use the left edge for x and center vertically by adding half of the sphere's height.
    const relativeX = sphereRect.left - parentRect.left;
    const relativeY = sphereRect.top - parentRect.top + sphereRect.height / 2;

    setLabelPosition({ x: relativeX, y: relativeY });
    setLabelShowing(true);
  };



  const hideLabel = (event) => {
    setLabelShowing(false);
  }

  return (
    <div style={{position:"relative"}}>
      <div
        className={`node-sphere ${current && "current"}`}
        onClick={handleClicked}
        onMouseEnter={showLabel}
        onMouseLeave={hideLabel}
        style={{
          backgroundImage: `url(${environment.thumbnailUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      {labelShowing && (
        <Label
          labelPosition={labelPosition}
          displayName={environment.environmentName}
          alignment="left"
        />
      )}
    </div>
  );
}



export default EnvironmentMap;
