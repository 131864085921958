import React from "react";
import { useParams } from "react-router-dom";
import UnityWebViewer from "../components/UnityWebViewer";


const ExperiencePage = () => {
  const { experienceId } = useParams();

  return (
    <div className="App">
      {/* <h2>Experience ID: {experienceId}</h2> */}
      <UnityWebViewer experienceId={experienceId} />
    </div>
  );
};

export default ExperiencePage;
