import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import axios from "axios";
import RecordingPanel from "./RecordingPanel";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as MicrophoneIcon } from "../assets/microphone.svg";

const VoiceRecorder = forwardRef(
  ({ doneRecording, assessmentComplete }, ref) => {
    const [recording, setRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const silenceTimerRef = useRef(null);
    const silenceIntervalRef = useRef(null); // new ref for interval
    const [fileName, setFileName] = useState("Test");
    const [fileToUpload, setFileToUpload] = useState(null);

    useImperativeHandle(ref, () => ({
      startRecording,
      uploadFileToS3,
    }));

    const startRecording = async (assessmentId) => {
      setFileName(`${assessmentId}.webm`);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      source.connect(analyserRef.current);

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start();
      setRecording(true);
      monitorSilence(); // start monitoring for silence
    };

    // Optionally, you can also start monitoring in a useEffect:
    // useEffect(() => {
    //   if (recording) {
    //     monitorSilence();
    //   }
    // }, [recording]);

    const monitorSilence = () => {
      const analyser = analyserRef.current;
      const dataArray = new Uint8Array(analyser.fftSize);

      const checkSilence = () => {
        analyser.getByteTimeDomainData(dataArray);
        const sum = dataArray.reduce(
          (acc, value) => acc + (value - 128) ** 2,
          0
        );
        const average = Math.sqrt(sum / dataArray.length) / 128;
        console.log("Volume level:", average);

        // If volume is below threshold, start the silence timer
        if (average < 0.01) {
          if (silenceTimerRef.current === null) {
            silenceTimerRef.current = setTimeout(() => {
              console.log("3 seconds of silence detected. Stopping recording.");
              stopRecording();
            }, 4000);
          }
        } else {
          // If noise is detected, clear the timer
          if (silenceTimerRef.current !== null) {
            clearTimeout(silenceTimerRef.current);
            silenceTimerRef.current = null;
          }
        }
      };

      // Check silence every 100ms
      silenceIntervalRef.current = setInterval(checkSilence, 100);
    };

    const stopRecording = () => {
      console.log("STOP recording called");
      // Clear the silence checking interval and timer if they exist
      if (silenceIntervalRef.current) {
        clearInterval(silenceIntervalRef.current);
        silenceIntervalRef.current = null;
      }
      if (silenceTimerRef.current) {
        clearTimeout(silenceTimerRef.current);
        silenceTimerRef.current = null;
      }

      // Stop the media recorder and handle onstop event
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        audioChunksRef.current = [];

        // Prepare file for upload
        const file = new File([audioBlob], fileName, { type: "audio/webm" });
        setFileToUpload(file);
        doneRecording();
      };

      mediaRecorderRef.current.stop();
      setRecording(false);
    };

    const uploadFileToS3 = async (presignedUrl) => {
      try {
        const response = await axios.put(presignedUrl, fileToUpload, {
          headers: {
            "Content-Type": "audio/webm",
          },
        });
        if (response.status === 200) {
          assessmentComplete();
          console.log("File uploaded successfully");
        } else {
          console.error("File upload failed:", response.data);
        }
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response ? error.response.data : error.message
        );
      }
    };

    return (
      <Box>
        {recording && (
          <Box
            sx={{
              position: "fixed",
              bottom: 25,
              left: "50%",
              transform: "translateX(-50%)",
              width: 150,
              // backgroundColor: "#7CFDFF",
              backgroundColor: "#212121",
              boxShadow: 3,
              borderRadius: 8,
              p: 2,
              zIndex: 1000,
              color: "#ccc",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Pink circle with microphone inside */}
              <Box
                sx={{
                  backgroundColor: "#FF437C",
                  borderRadius: "50%",
                  width: 50,
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                <MicrophoneIcon width={24} height={24} />
              </Box>
              <Typography variant="h5" ml={1}>
                Recording...
              </Typography>
              {/* MUI stop recording button */}
              <Button
                variant="contained"
                color="#7CFDFF"
                onClick={stopRecording}
                disabled={!recording}
                sx={{ mt: 2 }}
              >
                I'm done
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

export default VoiceRecorder;
