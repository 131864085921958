import { Box, Typography } from "@mui/material";
import React from "react";

const Label = ({
  labelPosition,
  displayName,
  alignment = "top", // Options: "left", "right", "top", "bottom", "center"
  offsetLeft = 0, // New prop to adjust the left position for screenspace changes
}) => {
  const transform = (() => {
    switch (alignment) {
      case "left":
        return "translate(-105%, -50%)";
      case "right":
        return "translate(5%, -50%)";
      case "top":
        return "translate(-50%, -105%)";
      case "bottom":
        return "translate(-50%, 5%)";
      case "center":
      default:
        return "translate(-50%, -50%)";
    }
  })();

  return (
    <Box
      sx={{
        position: "absolute",
        top: labelPosition.y,
        left: labelPosition.x + offsetLeft,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        color: "#fff",
        padding: "8px 16px",
        borderRadius: "10px",
        pointerEvents: "none",
        transform,
        width: "auto",
        maxWidth: "none",
        whiteSpace: "nowrap",
      }}
    >
      <Typography variant="h5">{displayName}</Typography>
    </Box>
  );
};

export default Label;
